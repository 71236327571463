<template>
  <div class="element">
    <div class="element__wrapper">
      <div class="element__wrapper--content">
        {{ $t('new_units.objects.approved') }}
      </div>
      <div class="element__wrapper--line" />
    </div>
  </div>
</template>
<script setup>
import { useI18n } from '@/hooks/useI18n'

const $t = useI18n()

const props = defineProps({
  title: {
    type: String,
    default: 'saved'
  }
})
</script>
<style lang="scss">
@import '@/components/unitsNew/assets/styles/Objects/ObjectsNewElementsHeader.scss';
</style>
