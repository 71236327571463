<template>
  <div class="object-shifts flex">
    <div
      class="object-shifts__table-wrapper flex flex-col gap-4 pt-5 pb-2 w-7/12"
    >
      <div class="flex justify-between items-start px-5">
        <span class="text-base">{{
          $t('new_units.objects.fields.shifts.title')
        }}</span>
        <UnitsAddButton :active-tab="activeTab" @click.native="addShift" />
      </div>
      <div class="px-5">
        <UnitsDateTimePicker
          class="object-shifts__date-filter"
          @onChange="changePeriod"
        />
      </div>
      <UnitsTable
        :table-data="shiftsTable.newList"
        :titles="shiftsTable.titles"
        :options="shiftsTable.options"
        class="object-shifts__new-table"
        @onRowClick="rowClick"
        @onDelete="deleteShift"
      />
      <div class="object-shifts__approved-table">
        <ObjectsNewElementsHeader v-if="shiftsTable.list.length" class="px-5" />
        <UnitsTable
          :show-header="false"
          :table-data="shiftsTable.list"
          :titles="shiftsTable.titles"
          :options="shiftsTable.options"
          :empty="emptyData"
          class="object-shifts__table"
          @onRowClick="rowClick"
          @onDelete="deleteShift"
        />
      </div>
      <div class="flex flex-col flex-grow justify-end gap-2">
        <UnitsDeletedRecovery
          v-if="shiftsTable.options.recovered.length"
          :count="shiftsTable.options.recovered.length"
          :active-tab="activeTab"
          @onCancel="cancelDeletion"
          @onDeleteRecovered="finalDeletion"
        />
        <UnitsCheckbox
          :value="isPreviousShiftsHidden"
          :label="$t('new_units.objects.fields.shifts.hide_old_shifts')"
          class="px-5"
          @onChange="changePreviousShiftsHidden"
        />
      </div>
    </div>
    <ShiftsDetails
      v-if="isNewShift || selectedShift"
      :shift="selectedShift"
      :errors="errorData"
      class="w-5/12"
      @onApprove="approveShift"
      @onUpdate="updateShift"
      @onCancel="cancelChanges"
    />
  </div>
</template>
<script setup>
import { ref, onBeforeMount, watch, getCurrentInstance } from 'vue'

import { useI18n } from '@/hooks/useI18n'
import { createVuexHelpers } from 'vue2-helpers'
import { unitDriversApi } from '@/api'
import { refs } from '@/components/unitsNew/helpers/index.js'

import ShiftsDetails from './ShiftsDetails.vue'
import UnitsTable from '@/components/unitsNew/components/UnitsTable.vue'
import UnitsCheckbox from '@/components/unitsNew/components/UnitsCheckbox.vue'
import UnitsAddButton from '@/components/unitsNew/components/UnitsAddButton.vue'
import UnitsDateTimePicker from '@/components/unitsNew/components/UnitsDateTimePicker.vue'
import UnitsDeletedRecovery from '@/components/unitsNew/components/UnitsDeletedRecovery.vue'
import ObjectsNewElementsHeader from '@/components/unitsNew/components/Objects/ObjectsNewElementsHeader.vue'

const { useGetters, useMutations } = createVuexHelpers()

const $t = useI18n()
const instance = getCurrentInstance()
const activeTab = 'shifts'

const { activeObject, activeObjectShifts, activeObjectNewShifts, modalType } =
  useGetters('units', [
    'activeObject',
    'activeObjectShifts',
    'activeObjectNewShifts',
    'modalType'
  ])

const { userById } = useGetters('users', ['userById'])

const { SET_ERRORS, SET_SHIFTS_ACTIVE_OBJECT, SET_NEW_SHIFTS_ACTIVE_OBJECT } =
  useMutations('units', [
    'SET_ERRORS',
    'SET_SHIFTS_ACTIVE_OBJECT',
    'SET_NEW_SHIFTS_ACTIVE_OBJECT'
  ])

const emits = defineEmits(['onGetList', 'changePeriod'])

const isPreviousShiftsHidden = ref(false)
const datePeriod = ref([])
const emptyData = {
  icon: activeTab,
  text: $t('new_units.objects.fields.shifts.no_shifts')
}
const errorData = ref([])

const hidePreviousShifts = () => {
  shiftsTable.value.list = shiftsTable.value.list.filter((item) => {
    const time = new Date(item.date_to).getTime()

    if (time >= Date.now()) return item
  })
}

const changePeriod = (date) => {
  datePeriod.value = date

  isNewShift.value = false

  emits('changePeriod', date)
}

const changePreviousShiftsHidden = (value) => {
  isPreviousShiftsHidden.value = value

  if (value) return hidePreviousShifts()

  emits('changePeriod', datePeriod.value)
}

const shiftsTable = ref({
  list: [],
  newList: [],
  titles: [
    {
      label: $t('new_units.objects.fields.shifts.table_columns.from'),
      prop: 'date_from',
      width: '30',
      headerAlign: 'center',
      align: 'center',
      icon: true,
      class: []
    },
    {
      label: $t('new_units.objects.fields.shifts.table_columns.to'),
      prop: 'date_to',
      width: '30',
      headerAlign: 'center',
      align: 'center',
      icon: true,
      class: []
    },
    {
      label: $t('new_units.objects.fields.shifts.table_columns.driver'),
      prop: 'user.name',
      width: '30',
      headerAlign: 'center',
      align: 'center',
      icon: true,
      class: ['greyToneThird', 'max-w-textShort']
    },
    {
      label: '',
      prop: 'delete',
      width: '10',
      headerAlign: 'center',
      align: 'center'
    }
  ],
  options: {
    hasCheckboxColumn: false,
    recovered: []
  }
})

const isNewShift = ref(false)
const selectedShift = ref(null)

const deleteTime = 10000
let myTimeout = null

const cancelChanges = (shift) => {
  selectedShift.value = shift
}

const approveShift = async () => {
  await refs.shiftsValidation.value.validate(async (valid) => {
    if (valid) {
      await unitDriversApi.createUnitDriver(
        {
          ...selectedShift.value,
          id: null,
          unit: { id: activeObject.value.id }
        },
        (res) => {
          isNewShift.value = false

          errorData.value = []
          SET_ERRORS([])

          const driver = userById.value(res.data.user.id)

          const newShift = {
            ...res.data,
            user: driver || { id: '' }
          }

          shiftsTable.value.list.push(newShift)

          shiftsTable.value.newList = []

          selectedShift.value = newShift

          SET_SHIFTS_ACTIVE_OBJECT(shiftsTable.value.list)
          SET_NEW_SHIFTS_ACTIVE_OBJECT(shiftsTable.value.newList)

          instance.proxy.$showSuccess(
            $t('new_units.objects.fields.shifts.success.approved')
          )
        },
        (error) => {
          errorData.value = error.response.data.errors.map((item) => item.field)
          SET_ERRORS([activeTab])

          instance.proxy.$showError(error.response.data.message)
        }
      )
    } else {
      return false
    }
  })
}

const addShift = () => {
  if (isNewShift.value)
    return instance.proxy.$showError(
      $t('new_units.objects.fields.shifts.errors.approved')
    )

  isNewShift.value = true

  const newShift = {
    id: Math.random().toString(),
    user: { id: '', name: $t('new_units.not_selected') },
    date_from: $t('new_units.unspecified'),
    date_to: $t('new_units.unspecified'),
    custom_fields: [],
    isNew: true
  }

  shiftsTable.value.newList.push(newShift)

  selectedShift.value = newShift

  SET_NEW_SHIFTS_ACTIVE_OBJECT(shiftsTable.value.newList)
}

const updateShift = () => {
  unitDriversApi.updateUnitDriver(
    selectedShift.value,
    () => {
      errorData.value = []
      SET_ERRORS([])
      instance.proxy.$showSuccess(
        $t('new_units.objects.fields.shifts.success.updated')
      )
    },
    (error) => {
      errorData.value = error.response.data.errors.map((item) => item.field)
      SET_ERRORS([activeTab])
      instance.proxy.$showError(error.response.data.message)
    }
  )
}

const rowClick = (shift) => {
  selectedShift.value = shift
}

const finalDeletion = () => {
  if (!shiftsTable.value.options.recovered.length) return

  isNewShift.value = false

  shiftsTable.value.options.recovered.forEach(async (id) => {
    const deletedShift = shiftsTable.value.newList.find(
      (newShift) => newShift.id === id
    )

    if (deletedShift) {
      shiftsTable.value.newList = shiftsTable.value.newList.filter(
        (shift) => shift.id !== id
      )
    } else {
      await unitDriversApi.deleteUnitShift(
        id,
        () => {
          shiftsTable.value.list = shiftsTable.value.list.filter(
            (shift) => shift.id !== id
          )
        },
        (error) => {
          instance.proxy.$showError(error.response.data.message)
        }
      )
    }
  })

  if (shiftsTable.value.options.recovered.includes(selectedShift.value.id)) {
    errorData.value = []
    SET_ERRORS([])
  }

  shiftsTable.value.options.recovered = []
  selectedShift.value = null

  clearTimeout(myTimeout)

  SET_SHIFTS_ACTIVE_OBJECT(shiftsTable.value.list)
  SET_NEW_SHIFTS_ACTIVE_OBJECT(shiftsTable.value.newList)
}

const deletionTimeout = () => {
  myTimeout = setTimeout(finalDeletion, deleteTime)
}

const deleteShift = (shift) => {
  if (shiftsTable.value.options.recovered.includes(shift.item.id))
    return instance.proxy.$showError(
      $t('new_units.objects.fields.shifts.success.already_deleted')
    )

  shiftsTable.value.options.recovered.push(shift.item.id)

  clearTimeout(myTimeout)
  deletionTimeout()
}

const cancelDeletion = () => {
  shiftsTable.value.options.recovered.splice(0)

  clearTimeout(myTimeout)
}

watch(activeObjectShifts, (val) => {
  shiftsTable.value.list = val

  val.length ? (selectedShift.value = val[0]) : (selectedShift.value = null)
})

onBeforeMount(() => {
  if (modalType.value === 'edit') {
    shiftsTable.value.list = activeObjectShifts.value
    shiftsTable.value.newList = activeObjectNewShifts.value

    if (activeObjectShifts.value.length)
      selectedShift.value = activeObjectShifts.value[0]
  }
})
</script>
<style lang="scss">
@import '@/components/unitsNew/assets/styles/Objects/Shifts.scss';
</style>
